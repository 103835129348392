import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSignOutAlt, faCog, faBuilding, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import './Header.css';

export default function Header() {
  const { currentUser, logout } = useAuth();
  const { t, i18n } = useTranslation(); // Use both `t` and `i18n` for translation and language change
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/get-started', { replace: true });
    } catch (error) {
      console.error('Failed to log out: ' + error.message);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <header className="header-container">
      <div className="navbar-brand">
        <img src={`${process.env.PUBLIC_URL}/rentfy-logo.svg`} alt="Logo" className="logo" />
      </div>
      <button className="menu-toggle" onClick={toggleMenu}>
        &#9776;
      </button>
      <nav className={`navbar-links ${isMenuOpen ? 'open' : ''}`}>
        <a href="/" className={location.pathname === '/' ? 'active' : ''}>
          <FontAwesomeIcon icon={faHome} /> <span className="link-text">{t('home')}</span>
        </a>
        <a href="/rent-to-own" className={location.pathname === '/rent-to-own' ? 'active' : ''}>
          <FontAwesomeIcon icon={faBuilding} /> <span className="link-text">{t('rto')}</span>
        </a>
        {currentUser && (
          <>
            <a href="/need-help" className="balance-link">
              <FontAwesomeIcon icon={faQuestionCircle} /> <span className="link-text">{t('need_help')}</span>
            </a>
            <a href="/settings" className="settings-link">
              <FontAwesomeIcon icon={faCog} /> <span className="link-text">{t('settings')}</span>
            </a>
            <a href="#" onClick={handleLogout} className="logout-link">
              <FontAwesomeIcon icon={faSignOutAlt} /> <span className="link-text">{t('sign_out')}</span>
            </a>
          </>
        )}
      </nav>
      <div className="language-dropdown">
        <select onChange={(e) => changeLanguage(e.target.value)} value={i18n.language}>
          <option value="en">English</option>
          <option value="fr">Français</option>
        </select>
      </div>
      {!currentUser && (
        <div className="auth-actions">
          <button onClick={() => navigate('/get-started')}>{t('get_started')}</button>
        </div>
      )}
    </header>
  );
}
