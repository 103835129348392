import React from 'react';
import { useTranslation } from 'react-i18next';
import './Terms.css';

const Terms = () => {
  const { t } = useTranslation();
  return (
    <div className="terms-container">
      <h1>{t('terms_of_service')}</h1>
      <section>
        <h2>{t('introduction_title')}</h2>
        <p>{t('introduction_text')}</p>
      </section>
      <section>
        <h2>{t('acceptance_of_terms_title')}</h2>
        <p>{t('acceptance_of_terms_text')}</p>
      </section>
      <section>
        <h2>{t('cookies_title')}</h2>
        <p>{t('cookies_text')}</p>
      </section>
      <section>
        <h2>{t('license_title')}</h2>
        <p>{t('license_text')}</p>
      </section>
      {/* Add more sections as needed */}
    </div>
  );
}

export default Terms;