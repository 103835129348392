import React from 'react';
import { Container, Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './FAQ.css'; // Create this CSS file for styling

const FAQ = () => {
  const { t } = useTranslation();

  const faqs = [
    {
      question: t('faq_question_1'),
      answer: t('faq_answer_1'),
    },
    {
      question: t('faq_question_2'),
      answer: t('faq_answer_2'),
    },
    {
      question: t('faq_question_3'),
      answer: t('faq_answer_3'),
    },
    {
      question: t('faq_question_4'),
      answer: t('faq_answer_4'),
    },
    {
      question: t('faq_question_5'),
      answer: t('faq_answer_5'),
    },
    {
      question: t('faq_question_6'),
      answer: t('faq_answer_6'),
    },
    {
      question: t('faq_question_7'),
      answer: t('faq_answer_7'),
    },
    {
      question: t('faq_question_8'),
      answer: t('faq_answer_8'),
    },
    {
      question: t('faq_question_9'),
      answer: t('faq_answer_9'),
    },
  ];

  return (
    <Container className="faq-container">
      <h2 className="text-center mb-4">{t('frequently_asked_questions')}</h2>
      <Accordion>
        {faqs.map((faq, index) => (
          <Accordion.Item eventKey={index.toString()} key={index}>
            <Accordion.Header>{faq.question}</Accordion.Header>
            <Accordion.Body>{faq.answer}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </Container>
  );
};

export default FAQ;
