import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Footer.css';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>{t('about_us')}</h3>
          <p><Link to="/need-help">{t('help_center')}</Link></p>
          <p><Link to="/faq">{t('faq')}</Link></p>
        </div>
        <div className="footer-section">
          <h3>{t('legal')}</h3>
          <p><Link to="/privacy">{t('privacy_policy')}</Link></p>
          <p><Link to="/terms">{t('terms_of_use')}</Link></p>
        </div>
        <div className="footer-section">
          <h3>{t('social')}</h3>
          <p><a href="https://www.instagram.com/rentfyhq/" target="_blank" rel="noopener noreferrer">{t('instagram')}</a></p>
        </div>
      </div>
      <p>© 2024 Rentfy. {t('all_rights_reserved')}</p>
    </footer>
  );
};

export default Footer;
