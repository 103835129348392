import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../context/AuthContext';
import { db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import './Onboarding.css';

export default function Onboarding() {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [page, setPage] = useState(0); // Start at 0 for the welcome page
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    address: '',
    age: '',
    rentingStatus: '',
    householdSize: '',
    occupation: '',
    income: '',
    employmentType: '',
    creditScore: '',
    debts: '',
    downPaymentSavings: '',
    monthlyPayment: '',
    currentRent: '',
    leaseEndDate: '',
    satisfaction: '',
    relocationPreference: '',
    desiredArea: '',
    heardAboutRTO: '',
    rtoInterest: '',
    previousRTOExperience: '',
    homeType: '',
    bedrooms: '',
    bathrooms: '',
    homePreferences: '',
    moveInTimeline: '',
    rtoGoal: '',
    rtoCommitment: '',
    rtoConcerns: '',
    additionalInfo: '',
    financialCounselingInterest: '',
    followUpMethod: '',
    financingOption: '',
    referenceName: '', // New field added
  });

  const [warning, setWarning] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState(null); // ReCAPTCHA token state

  useEffect(() => {
    const loadSavedData = async () => {
      if (currentUser) {
        const docRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const savedData = docSnap.data().onboardingData || {};
          setFormData((prevData) => ({
            ...prevData,
            ...savedData,
          }));
        }
      }
    };

    loadSavedData();
  }, [currentUser]);

  const saveProgress = async () => {
    if (currentUser) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, {
        onboardingData: formData,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNextPage = async () => {
    await saveProgress(); // Save progress when moving to the next page

    const pageFields = Object.keys(formData).slice((page - 1) * 5, page * 5);
    const emptyFields = pageFields.filter((field) => formData[field].trim() === '');

    if (emptyFields.length > 0) {
      setWarning(t('incomplete_fields_warning'));
    } else {
      setWarning('');
    }

    if (!recaptchaToken) {
      setWarning(t('complete_captcha_warning'));
      return;
    }

    setPage(page + 1);
  };

  const handlePrevPage = async () => {
    await saveProgress(); // Save progress when moving to the previous page
    setPage(page - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentUser) {
      console.error(t('no_user_logged_in'));
      return;
    }

    if (!recaptchaToken) {
      setWarning(t('complete_captcha_warning'));
      return;
    }

    try {
      const userDocRef = doc(db, 'users', currentUser.uid);

      // Update user document in Firestore with final onboarding data
      await updateDoc(userDocRef, {
        onboardingData: formData,
        onboardingCompleted: true, // Mark onboarding as completed
      });

      // Display success message and redirect to home page
      alert(t('onboarding_success_message'));
      navigate('/');
    } catch (error) {
      console.error(t('error_saving_onboarding_data'), error);
    }
  };

  const renderPageContent = () => {
    if (page === 0) {
      return (
        <div className="welcome-container">
          <h2>{t('welcome_to_rentfy')}</h2>
          <p>{t('welcome_message')}</p>
          <button onClick={() => setPage(1)} className="start-button">{t('start')}</button>
        </div>
      );
    }

    switch (page) {
      case 1:
        return (
          <div>
            <h2>{t('basic_information')}</h2>
            <label>{t('full_name')}: <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} /></label>
            <label>{t('email')}: <input type="email" name="email" value={formData.email} onChange={handleChange} /></label>
            <label>{t('current_address')}: <input type="text" name="address" value={formData.address} onChange={handleChange} /></label>
            <label>{t('age')}: <input type="number" name="age" value={formData.age} onChange={handleChange} /></label>
            <label>{t('reference_email')}: <input type="email" name="referenceName" value={formData.referenceName} onChange={handleChange} /></label>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={setRecaptchaToken}
            />
            <button onClick={handleNextPage}>{t('next')}</button>
          </div>
        );
      case 2:
        return (
          <div>
            <h2>{t('financial_information')}</h2>
            <label>{t('current_occupation')}: <input type="text" name="occupation" value={formData.occupation} onChange={handleChange} /></label>
            <label>{t('monthly_annual_income')}: <input type="text" name="income" value={formData.income} onChange={handleChange} /></label>
            <label>{t('employment_status')}: <input type="text" name="employmentType" value={formData.employmentType} onChange={handleChange} /></label>
            <label>{t('credit_score')}: <input type="text" name="creditScore" value={formData.creditScore} onChange={handleChange} /></label>
            <label>{t('outstanding_debts')}: <input type="text" name="debts" value={formData.debts} onChange={handleChange} /></label>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={setRecaptchaToken}
            />
            <button onClick={handlePrevPage}>{t('back')}</button>
            <button onClick={handleNextPage}>{t('next')}</button>
          </div>
        );
      case 3:
        return (
          <div>
            <h2>{t('current_living_situation')}</h2>
            <label>{t('down_payment_savings')}: <input type="text" name="downPaymentSavings" value={formData.downPaymentSavings} onChange={handleChange} /></label>
            <label>{t('monthly_payment_capability')}: <input type="text" name="monthlyPayment" value={formData.monthlyPayment} onChange={handleChange} /></label>
            <label>{t('current_rent')}: <input type="text" name="currentRent" value={formData.currentRent} onChange={handleChange} /></label>
            <label>{t('lease_end_date')}: <input type="date" name="leaseEndDate" value={formData.leaseEndDate} onChange={handleChange} /></label>
            <label>{t('living_satisfaction')}: <input type="text" name="satisfaction" value={formData.satisfaction} onChange={handleChange} /></label>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={setRecaptchaToken}
            />
            <button onClick={handlePrevPage}>{t('back')}</button>
            <button onClick={handleNextPage}>{t('next')}</button>
          </div>
        );
      case 4:
        return (
          <div>
            <h2>{t('rto_specific_questions')}</h2>

            <label>
              {t('relocation_preference')} 
              <input type="text" name="relocationPreference" value={formData.relocationPreference} onChange={handleChange} />
            </label>

            <label>
              {t('desired_area')} 
              <input type="text" name="desiredArea" value={formData.desiredArea} onChange={handleChange} />
            </label>

            <label>
              {t('how_heard_about_rto')} 
              <input type="text" name="heardAboutRTO" value={formData.heardAboutRTO} onChange={handleChange} />
            </label>

            <label>
              {t('rto_interest')} 
              <input type="text" name="rtoInterest" value={formData.rtoInterest} onChange={handleChange} />
            </label>

            <label>
              {t('previous_rto_experience')} 
              <input type="text" name="previousRTOExperience" value={formData.previousRTOExperience} onChange={handleChange} />
            </label>

            <label>
              {t('home_type')} 
              <input type="text" name="homeType" value={formData.homeType} onChange={handleChange} />
            </label>

            <label>
              {t('bedrooms_bathrooms')} 
              <input type="text" name="bedrooms" value={formData.bedrooms} onChange={handleChange} />
            </label>

            <label>
              {t('home_preferences')} 
              <input type="text" name="homePreferences" value={formData.homePreferences} onChange={handleChange} />
            </label>

            <label>
              {t('financing_option')} 
            </label>
            <div>
              <label>
                <input 
                  type="radio" 
                  name="financingOption" 
                  value={t('bank_financing')} 
                  checked={formData.financingOption === t('bank_financing')}
                  onChange={handleChange}
                />
                {t('bank_financing')}
              </label>
            </div>

            <div>
              <label>
                <input 
                  type="radio" 
                  name="financingOption" 
                  value={t('seller_financing')} 
                  checked={formData.financingOption === t('seller_financing')}
                  onChange={handleChange}
                />
                {t('seller_financing')}
              </label>
            </div>

            <div>
              <label>
                <input 
                  type="radio" 
                  name="financingOption" 
                  value={t('paying_rent_online')} 
                  checked={formData.financingOption === t('paying_rent_online')}
                  onChange={handleChange}
                />
                {t('paying_rent_online')}
              </label>
            </div>

            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={setRecaptchaToken}
            />
            <button onClick={handlePrevPage}>{t('back')}</button>
            <button onClick={handleNextPage}>{t('next')}</button>
          </div>
        );
      case 5:
        return (
          <div>
            <h2>{t('future_plans')}</h2>
            <label>{t('move_in_timeline')}: <input type="text" name="moveInTimeline" value={formData.moveInTimeline} onChange={handleChange} /></label>
            <label>{t('rto_goal')}: <input type="text" name="rtoGoal" value={formData.rtoGoal} onChange={handleChange} /></label>
            <label>{t('rto_commitment')}: <input type="text" name="rtoCommitment" value={formData.rtoCommitment} onChange={handleChange} /></label>
            <label>{t('rto_commitment_question')}: <input type="text" name="rtoCommitment" value={formData.rtoCommitment} onChange={handleChange} /></label>
            <label>{t('rto_concerns')}: <input type="text" name="rtoConcerns" value={formData.rtoConcerns} onChange={handleChange} /></label>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={setRecaptchaToken}
            />
            <button onClick={handlePrevPage}>{t('back')}</button>
            <button onClick={handleNextPage}>{t('next')}</button>
          </div>
        );
      case 6:
        return (
          <div>
            <h2>{t('additional_information')}</h2>
            <label>{t('additional_info')}: <input type="text" name="additionalInfo" value={formData.additionalInfo} onChange={handleChange} /></label>
            <label>{t('financial_counseling_interest')}: <input type="text" name="financialCounselingInterest" value={formData.financialCounselingInterest} onChange={handleChange} /></label>
            <label>{t('follow_up_method')}: <input type="text" name="followUpMethod" value={formData.followUpMethod} onChange={handleChange} /></label>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={setRecaptchaToken}
            />
            <button onClick={handlePrevPage}>{t('back')}</button>
            <button onClick={handleSubmit}>{t('submit')}</button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="onboarding-container">
      {warning && <div className="alert alert-warning">{warning}</div>}
      {renderPageContent()}
    </div>
  );
}
