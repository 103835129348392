import React, {useRef, useState} from 'react';
import { useAuth } from '../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import './Signup.css';

export default function Signup() {
  const { t } = useTranslation();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const roleRef = useRef();
  const locationRef = useRef();
  const commentRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError(t('passwords_do_not_match'));
    }

    if (!recaptchaToken) {
      return setError(t('complete_captcha'));
    }

    try {
      setError('');
      setMessage('');
      setLoading(true);

      const userCredential = await signup(emailRef.current.value, passwordRef.current.value);
      const user = userCredential.user;

      await setDoc(doc(db, 'users', user.uid), {
        firstName: firstNameRef.current.value,
        lastName: lastNameRef.current.value,
        email: emailRef.current.value,
        role: roleRef.current.value,
        location: locationRef.current.value,
        comment: commentRef.current.value || '',
        onboardingCompleted: false,
        recaptchaToken: recaptchaToken, // Store the reCAPTCHA token
      });

      setMessage(t('signup_success'));

      setTimeout(() => {
        if (roleRef.current.value === 'tenant') {
          navigate('/onboarding');
        } else if (roleRef.current.value === 'real-estate-investor') {
          navigate('/onboarding-investor');
        }
      }, 2000);
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setError(t('email_in_use'));
      } else {
        console.error('Failed to create an account:', error);
        setError(t('signup_failed'));
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="signup-container">
      <div className="signup-card">
        <h2 className="text-center">{t('get_started')}</h2>
        {error && <div className="alert alert-danger">{error}</div>}
        {message && <div className="alert alert-success">{message}</div>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="first-name">{t('first_name')}</label>
            <input type="text" id="first-name" ref={firstNameRef} required className="form-control" />
          </div>
          <div className="form-group">
            <label htmlFor="last-name">{t('last_name')}</label>
            <input type="text" id="last-name" ref={lastNameRef} required className="form-control" />
          </div>
          <div className="form-group">
            <label htmlFor="email">{t('email')}</label>
            <input type="email" id="email" ref={emailRef} required className="form-control" />
          </div>
          <div className="form-group">
            <label htmlFor="role">{t('role')}</label>
            <select id="role" ref={roleRef} required className="form-control">
              <option value="tenant">{t('tenant')}</option>
              <option value="real-estate-investor">{t('real_estate_investor')}</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="location">{t('location')}</label>
            <select id="location" ref={locationRef} required className="form-control">
              <option value="canada">{t('canada')}</option>
              <option value="usa">{t('usa')}</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="comment">{t('comment_optional')}</label>
            <textarea id="comment" ref={commentRef} className="form-control"></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="password">{t('password')}</label>
            <input type="password" id="password" ref={passwordRef} required className="form-control" />
          </div>
          <div className="form-group">
            <label htmlFor="password-confirm">{t('confirm_password')}</label>
            <input type="password" id="password-confirm" ref={passwordConfirmRef} required className="form-control" />
          </div>
          <div className="form-group">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={setRecaptchaToken}
            />
          </div>
          <button disabled={loading} type="submit" className="btn btn-primary w-100" style={{ marginTop: '20px' }}>
            {t('sign_up')}
          </button>
        </form>
        <div className="text-center mt-3">
          {t('already_have_account')} <Link to="/login">{t('log_in')}</Link>
        </div>
        <p className="email-marketing">
          {t('email_marketing')}
        </p>
      </div>
    </div>
  );
}
