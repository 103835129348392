import React, { useEffect, useState } from 'react';
import './Home.css';
import { useAuth } from '../context/AuthContext';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, storage, functions } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { httpsCallable } from "firebase/functions";

export default function Home() {
  const { currentUser, onboardingCompleted } = useAuth();
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [payNowLoading, setPayNowLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        const docRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(docRef);
        const getDueDate = httpsCallable(functions, 'paymentGetDueDate');
        const responseDueDate = await getDueDate({uid: currentUser.uid});
        const getBalance = httpsCallable(functions, 'balanceGetBalance');
        const responseBalance = await getBalance({uid: currentUser.uid});
        const getPR = httpsCallable(functions, 'paymentGetPaymentRecord');
        const responsePR = await getPR({uid: currentUser.uid});
        if (docSnap.exists()) {
          const data = docSnap.data();
          const onboardingData = data.onboardingData || {};
          const investorOnboardingData = data.investorOnboardingData || {};
          const paymentHistory = responsePR.data || [];
          // Determine user type based on available data
          const isInvestor = !!investorOnboardingData.fullName;
          const onboardingLink = isInvestor ? '/onboarding-investor' : '/onboarding';

          const fullName = onboardingData.fullName || investorOnboardingData.fullName || '';
          const address = onboardingData.address || investorOnboardingData.location || '';

          setUserData({
            fullName: fullName,
            address: address,
            financingOption: onboardingData.financingOption || 'No finance option available',
            onboardingLink, // Store the appropriate link based on user type
            nextDueDate: responseDueDate.data || null,
            currentBalance: responseBalance.data || null,
            paymentHistory,
            ...data,
          });
        }
      }
      setLoading(false);
    };

    fetchUserData();
  }, [currentUser]);

  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return 'Good morning';
    } else if (currentHour < 18) {
      return 'Good afternoon';
    } else {
      return 'Good evening';
    }
  };

  const handlePayNowClick = async () => {
    try {
      setPayNowLoading(true);
      if (currentUser) {
        let payload = {
          currency: 'CAD',
          uid: currentUser.uid
        }
        const getPaymentLink = httpsCallable(functions, 'paymentPayRent'); // Name should match your deployed function name
        const response = await getPaymentLink(payload); // Pass the required parameters
        window.open(response.data.reusablePaymentLink, '_blank');
      }
    } catch (error) {
      console.error("Error fetching payment link:", error);
      // Handle error (e.g., show a message to the user)
    } finally {
      setPayNowLoading(false);
    }
  }

  const handleProfilePicChange = async (event) => {
    const file = event.target.files[0];
    if (file && currentUser) {
      const storageRef = ref(storage, `profilePictures/${currentUser.uid}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      // Update user's profile picture URL in Firestore
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, { profilePicURL: downloadURL });

      // Update the state to reflect the new profile picture
      setUserData((prevData) => ({ ...prevData, profilePicURL: downloadURL }));
    }
  };

  const handleAddressEditClick = () => {
    navigate('/update-profile');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const fullName = userData.fullName || 'First Name';
  const address = userData.address || 'Address';
  const financingOption = userData.financingOption || 'No finance option available';
  const onboardingLink = userData.onboardingLink || '/onboarding'; // Fallback to tenant onboarding if link is undefined

  // Fetch profile picture URL
  const profilePicURL = userData.profilePicURL || 'path/to/default-profile-picture.jpg';

  // Function to parse date strings stored in "YYYY-MM-DD" format to a Date object
  const parseDateString = (dateString) => {
    const [year, month, day] = dateString.split("-").map(Number);
    return new Date(Date.UTC(year, month - 1, day)); // Create a UTC date
  };
  const nextDueDate = userData.currentBalance ? userData.nextDueDate : null;

  const parsePRDateString = (dateString) => {
    return new Date(dateString.replace(' ', 'T')); // Converts "2024-08-27 18:58:04" to "2024-08-27T18:58:04"
  };

  let formattedDueDate = nextDueDate
      ? parseDateString(nextDueDate).toISOString().split('T')[0]
      : 'Due Date unavailable';
  //const monthlyRent = userData.monthlyRent || 'No available';

  return (
    <div className="home-container">
      <div className="home-content-wrapper">
        {!onboardingCompleted && (
            <div className="onboarding-message-card">
              <p>
                You need to complete your onboarding to have full access to our services.{' '}
                <a href={onboardingLink}>Click here to complete onboarding</a>.
              </p>
            </div>
        )}

        <div className="profile-greeting">
          <div className="profile-pic-container">
            <img src={profilePicURL} alt="Profile" className="profile-pic"/>
            <label htmlFor="upload-profile-pic" className="edit-profile-pic">
              Edit
            </label>
            <input
                type="file"
                id="upload-profile-pic"
                style={{display: 'none'}}
                accept="image/*"
                onChange={handleProfilePicChange}
            />
          </div>
          <div>
            <p>{getGreeting()}</p>
            <h2>{fullName}</h2>
            <p className="address">
              {address}{' '}
              <span className="edit-address" onClick={handleAddressEditClick}>
                Edit
              </span>
            </p>
          </div>
        </div>

        <div className="balance-card">
          <div className="balance-info">
            <p>Current Balance</p>
            <h1>${userData.currentBalance?.toFixed(2) || '0.00'}</h1>
            <p>{userData.currentBalance?.toFixed(2) || '0.00'} / {userData.downPaymentGoal || '0.00'} {financingOption}</p>
            <p>Next Payment Date: {formattedDueDate}</p>
            {nextDueDate && (
                <button onClick={handlePayNowClick} disabled={payNowLoading}
                        className="pay-now-button">{payNowLoading ? 'Loading...' : 'Pay Now'}</button>
            )}
          </div>
        </div>

        <div className="transactions-card">
          <h3>LAST transactions</h3>
          <table className="transactions-table">
            <thead>
              <tr>
                <th>Transaction ID</th>
                <th>Description</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Payment Method</th>
              </tr>
            </thead>
            <tbody>
              {userData.paymentHistory && userData.paymentHistory.length > 0 ? (
                  userData.paymentHistory.map((payment, index) => (
                    <tr key={index}>
                      <td>{payment.transaction_id}</td>
                      <td>Rent Payment</td>
                      <td>{parsePRDateString(payment.receivedDate).toLocaleDateString()}</td>
                      <td>${payment.amount.toFixed(2)}</td>
                      <td>{payment.payInMethodUsed}</td>
                    </tr>
                  ))
              ) : (
                  <td colSpan="5">No available transactions</td>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
