import './i18n'; 
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Signup from './Signup';
import Login from './Login';
import Home from './Home';
import ForgotPassword from './ForgotPassword';
import UpdateProfile from './UpdateProfile';
import Rewards from './Rewards';
import { AuthProvider, useAuth } from '../context/AuthContext';
import ResetPassword from './ResetPassword';
import GetStarted from './GetStarted';
import NeedHelp from './NeedHelp';
import Terms from './Terms';
import PrivacyPolicy from './PrivacyPolicy';
import Header from './Header';
import PrivateRoute from './PrivateRoute';
import Referral from './Referral';
import YourPaymentMethod from './YourPaymentMethod';
import PaymentHistory from './PaymentHistory';
import ViewHousehold from './ViewHousehold';
import Loading from './Loading';
import { LoadingProvider, useLoading } from '../context/LoadingContext';
import Footer from './Footer';
import PointSystem from './PointSystem';
import FAQ from './FAQ';
import './App.css';
import RentToOwn from './RentToOwn';
import RtoSignUp from './RtoSignUp';
import Information from './Information';
import Blog from './Blog';
import BlogPost from './BlogPost';
import Onboarding from './Onboarding';
import OnboardingInvestor from './OnboardingInvestor';
import Settings from './Settings';
import TestTranslation from './TestTranslation';

function App() {
  const { t } = useTranslation();
  console.log('Sample Translation:', t('some_key'));
  return (
    <HelmetProvider>
      <Router>
        <AuthProvider>
          <LoadingProvider>
            <AppContent />
          </LoadingProvider>
        </AuthProvider>
      </Router>
    </HelmetProvider>
  );
}

function AppContent() {
  const location = useLocation();
  const { isLoading } = useLoading();
  const { currentUser } = useAuth();
  const noHeaderPaths = [
    '/get-started',
    '/login',
    '/signup',
    '/property-manager-forgot-password',
    '/property-manager-login',
    '/property-manager-signup'
  ];

  const getPageMeta = () => {
    switch (location.pathname) {
      case '/':
        return {
          title: 'Home - Rentfy',
          description: 'Turn your rent payments into ownership with Rentfy.',
          image: 'https://rentfy.app/images/rentfy-logo.svg',
        };
      case '/get-started':
        return {
          title: 'Get Started - Rentfy',
          description: 'Start your journey to homeownership with Rentfy.',
          image: 'https://rentfy.app/images/get-started',
        };
      // Add more cases as needed for other routes
      default:
        return {
          title: 'Rentfy',
          description: 'Transforming the journey to homeownership.',
          image: 'https://rentfy.app/images/rentfy-logo.svg',
        };
    }
  };

  const pageMeta = getPageMeta();

  return (
    <div className="app-container">
      <Helmet>
        <title>{pageMeta.title}</title>
        <meta name="description" content={pageMeta.description} />
        <meta property="og:title" content={pageMeta.title} />
        <meta property="og:description" content={pageMeta.description} />
        <meta property="og:image" content={pageMeta.image} />
        <meta property="og:url" content={`https://rentfy.app${location.pathname}`} />
      </Helmet>

      {!noHeaderPaths.includes(location.pathname) && <Header />}
      <main>
        {isLoading && <Loading />}
        <Routes>
          {/* Redirect root path to /get-started unless user is logged in */}
          <Route
            path="/"
            element={
              currentUser ? <Navigate to="/home" /> : <Navigate to="/get-started" />
            }
          />
          <Route path="/get-started" element={<GetStarted />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/Referral" element={<Referral />} />
          <Route
            path="/home"
            element={
              currentUser ? (
                <PrivateRoute><Home /></PrivateRoute>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route path="/update-profile" element={<PrivateRoute><UpdateProfile /></PrivateRoute>} />
          <Route path="/rewards" element={<PrivateRoute><Rewards /></PrivateRoute>} />
          <Route path="/forgot-password" element={<ForgotPassword userType="users" />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword userType="tenant" />} />
          <Route path="/need-help" element={<NeedHelp />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/referral" element={<PrivateRoute><Referral /></PrivateRoute>} />
          <Route path="/your-payment-method" element={<PrivateRoute><YourPaymentMethod /></PrivateRoute>} />
          <Route path="/payment-history" element={<PrivateRoute><PaymentHistory /></PrivateRoute>} />
          <Route path="/view-household" element={<PrivateRoute><ViewHousehold /></PrivateRoute>} />
          <Route path="/point-system" element={<PrivateRoute><PointSystem /></PrivateRoute>} />
          <Route path="/RtoSignUp" element={<RtoSignUp />} />
          <Route path="/rent-to-own" element={<RentToOwn />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/information" element={<Information />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogPost />} />
          <Route path="/onboarding" element={<PrivateRoute><Onboarding /></PrivateRoute>} />
          <Route path="/onboarding-investor" element={<PrivateRoute><OnboardingInvestor /></PrivateRoute>} />
          <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
          <Route path="/test-translation" element={<TestTranslation />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
