import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Link } from 'react-router-dom';

const NeedHelp = () => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [description, setDescription] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'NeedHelpInbox'), {
        name,
        email,
        role,
        description,
        timestamp: new Date(),
      });
      setSubmitted(true);
      setName('');
      setEmail('');
      setRole('');
      setDescription('');
    } catch (error) {
      console.error(t('error_submitting_help_request'), error);
    }
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <Container>
      <h2>{t('need_help_title')}</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <Form.Label>{t('name')}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t('enter_your_name')}
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formEmail">
          <Form.Label>{t('email')}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t('enter_your_email')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formRole">
          <Form.Label>{t('role')}</Form.Label>
          <Form.Control
            as="select"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            required
          >
            <option value="">{t('select_your_role')}</option>
            <option value="Property Manager">{t('property_manager')}</option>
            <option value="Landlord">{t('landlord')}</option>
            <option value="Tenant">{t('tenant')}</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formDescription">
          <Form.Label>{t('description')}</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder={t('describe_your_issue')}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          {t('submit')}
        </Button>
      </Form>
      <div className="w-100 text-center mt-2">
        <Link onClick={goBack}>{t('cancel')}</Link>
      </div>
      <section className="faq-section">
        <h2>{t('faq_title')}</h2>
        <div className="faq-item">
          <h3>{t('what_is_rentfy')}</h3>
          <p>{t('what_is_rentfy_answer')}</p>
        </div>
        <div className="faq-item">
          <h3>{t('how_to_build_credit')}</h3>
          <p>{t('how_to_build_credit_answer')}</p>
        </div>
        <div className="faq-item">
          <h3>{t('what_is_rto')}</h3>
          <p>{t('what_is_rto_answer')}</p>
        </div>
        <Button href="/faq" variant="primary" type="submit">
          {t('more')}
        </Button>
      </section>
      {submitted && (
        <Alert variant="success" className="mt-3">
          {t('message_sent_success')}
        </Alert>
      )}
    </Container>
  );
};

export default NeedHelp;