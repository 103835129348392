import React, { useState, useEffect } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Import your firebase configuration
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import './RentToOwn.css';

export default function RentToOwn() {
  const { t, i18n } = useTranslation(); // Extract t function and i18n object from useTranslation
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [message, setMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleJoinWhitelist = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'RTOWhitelist'), { email, address });
      setMessage(t("whitelist_success"));
    } catch (error) {
      console.error('Error adding to whitelist: ', error);
      setMessage(t("whitelist_error"));
    }
  };

  // To maintain selected language across pages
  useEffect(() => {
    const savedLang = localStorage.getItem('selectedLanguage');
    if (savedLang) {
      i18n.changeLanguage(savedLang);
    }
  }, [i18n]);

  return (
    <div className="rent-to-own-container">
      <div className="description-container">
        <h2>{t('about_rto_title')}</h2>
        <p>{t('about_rto_description_1')}</p>
        <p>{t('about_rto_description_2')}</p>
        <p>{t('about_rto_description_3')}</p>
        <p>{t('about_rto_description_4')}</p>
        <p>{t('about_rto_description_5')}</p>
        <p>
          <strong>{t('rto_benefits')}</strong>
        </p>
        <ul>
          <li><strong>{t('build_equity_title')}:</strong> {t('build_equity_description')}</li>
          <li><strong>{t('flexibility_title')}:</strong> {t('flexibility_description')}</li>
          <li><strong>{t('credit_improvement_title')}:</strong> {t('credit_improvement_description')}</li>
          <li><strong>{t('stability_title')}:</strong> {t('stability_description')}</li>
          <li><strong>{t('locked_in_price_title')}:</strong> {t('locked_in_price_description')}</li>
          <li><strong>{t('investment_title')}:</strong> {t('investment_description')} <a href="/RtoSignUp">{t('download_ebook')}</a></li>
        </ul>
        <p>{t('rto_participation')}</p>
        <p>{t('rto_details')}</p>
        <form onSubmit={handleJoinWhitelist} className="whitelist-form">
          <label>{t('email')}: <input type="email" value={email} onChange={handleEmailChange} required /></label>
          <label>{t('address')}: <input type="text" value={address} onChange={handleAddressChange} required /></label>
          <button className="whitelist-btn" type="submit">{t('join_whitelist')}</button>
        </form>
        {message && <p className="message">{message}</p>}
        <p className="privacy-message" style={{ fontSize: '10px', color: 'grey', marginTop: '10px' }}>
          {t('privacy_notice')}
        </p>
      </div>
    </div>
  );
}
