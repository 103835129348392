import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { db, storage } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './Settings.css';

export default function Settings() {
  const { currentUser, updateEmail, updatePassword } = useAuth();
  const [selectedSection, setSelectedSection] = useState(null);
  const [fullName, setFullName] = useState('');  
  const [email, setEmail] = useState(currentUser?.email || '');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [address, setAddress] = useState('');
  const [unit, setUnit] = useState('');
  const [profilePic, setProfilePic] = useState(null);
  const [profilePicURL, setProfilePicURL] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        const docRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          const onboardingData = data.onboardingData || {};
          const investorOnboardingData = data.investorOnboardingData || {};
          // Pre-fill the form with existing data
          setFullName(onboardingData.fullName || investorOnboardingData.fullName || '');
          setAddress(onboardingData.address || investorOnboardingData.location || '');
          setUnit(data.unit || '');
          setProfilePicURL(data.profilePicURL || '');
        }
      }
    };

    fetchUserData();
  }, [currentUser]);

  const handleSectionClick = (section) => {
    setSelectedSection(section);
  };

  const handleProfilePicChange = (e) => {
    if (e.target.files[0]) {
      setProfilePic(e.target.files[0]);
    }
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    if (selectedSection === 'password-security' && password !== passwordConfirm) {
      return setError('Passwords do not match');
    }

    const promises = [];

    if (selectedSection === 'account-info' && email !== currentUser.email) {
      promises.push(updateEmail(email));
    }

    if (selectedSection === 'password-security' && password) {
      promises.push(updatePassword(password));
    }

    if (profilePic) {
      const storageRef = ref(storage, `profilePics/${currentUser.uid}`);
      promises.push(
        uploadBytes(storageRef, profilePic).then(async (snapshot) => {
          const downloadURL = await getDownloadURL(snapshot.ref);
          await updateDoc(doc(db, 'users', currentUser.uid), {
            profilePicURL: downloadURL,
          });
          setProfilePicURL(downloadURL);
        })
      );
    }

    if (selectedSection === 'account-info') {
      promises.push(
        updateDoc(doc(db, 'users', currentUser.uid), {
          fullName,  // Save updated full name
          address,
          unit,
        })
      );
    }

    Promise.all(promises)
      .then(() => {
        setMessage('Profile updated successfully');
      })
      .catch((error) => {
        setError('Failed to update account: ' + error.message);
      });
  };

  return (
    <div className="settings-container">
      <div className="sidebar">
        <h3>Accounts</h3>
        <ul>
          <li><a href="#" onClick={() => handleSectionClick('account-info')}>Name, address, account info</a></li>
          <li><a href="#" onClick={() => handleSectionClick('password-security')}>Password and security</a></li>
        </ul>
        <h3>Payment methods</h3>
        <ul>
          <li><a href="#">Seller banking information</a></li>
        </ul>
        <h3>Payment history</h3>
        <ul>
        <li><a href="#">Payment history</a></li>
        </ul>
      </div>

      <div className="content">
        {selectedSection === 'account-info' && (
          <div className="account-section">
            <h1>Account</h1>
            {error && <div className="alert alert-danger">{error}</div>}
            {message && <div className="alert alert-success">{message}</div>}
            <form onSubmit={handleUpdateProfile}>
              <div className="form-group">
                <label>Full Name</label>
                <input 
                  type="text" 
                  className="form-control" 
                  value={fullName} 
                  onChange={(e) => setFullName(e.target.value)} 
                  required 
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input 
                  type="email" 
                  className="form-control" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)} 
                  required 
                />
              </div>
              <div className="form-group">
                <label>Address</label>
                <input 
                  type="text" 
                  className="form-control" 
                  value={address} 
                  onChange={(e) => setAddress(e.target.value)} 
                  required 
                />
              </div>
              <div className="form-group">
                <label>Unit</label>
                <input 
                  type="text" 
                  className="form-control" 
                  value={unit} 
                  onChange={(e) => setUnit(e.target.value)} 
                />
              </div>
              <div className="form-group">
                <label>Profile Picture</label>
                <input 
                  type="file" 
                  className="form-control" 
                  onChange={handleProfilePicChange} 
                />
              </div>
              {profilePicURL && (
                <div className="form-group">
                  <img src={profilePicURL} alt="Profile" className="img-thumbnail mt-2" style={{ width: '150px', height: '150px' }} />
                </div>
              )}
              <button type="submit" className="btn btn-primary mt-3">Update Profile</button>
            </form>
          </div>
        )}

        {selectedSection === 'password-security' && (
          <div className="password-section">
            <h1>Password and Security</h1>
            {error && <div className="alert alert-danger">{error}</div>}
            {message && <div className="alert alert-success">{message}</div>}
            <form onSubmit={handleUpdateProfile}>
              <div className="form-group">
                <label>Password</label>
                <input 
                  type="password" 
                  className="form-control" 
                  placeholder="Leave blank to keep the same" 
                  value={password} 
                  onChange={(e) => setPassword(e.target.value)} 
                />
              </div>
              <div className="form-group">
                <label>Password Confirmation</label>
                <input 
                  type="password" 
                  className="form-control" 
                  placeholder="Leave blank to keep the same" 
                  value={passwordConfirm} 
                  onChange={(e) => setPasswordConfirm(e.target.value)} 
                />
              </div>
              <button type="submit" className="btn btn-primary mt-3">Update Password</button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
  }
