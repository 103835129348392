import React from 'react';
import { useTranslation } from 'react-i18next';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <div className="privacy-policy-container">
      <h1>{t('privacy_policy')}</h1>
      <p>
        {t('privacy_statement')} <a href="https://rentfy.app">https://www.rentfy.app</a>, {t('and_other_sites')}.
      </p>
      <h2>{t('information_we_collect')}</h2>
      <p>
        {t('collect_information_details')}
      </p>
      <h2>{t('how_we_use_information')}</h2>
      <p>
        {t('use_information_details')}
      </p>
      <h2>{t('information_sharing')}</h2>
      <p>
        {t('sharing_information_details')}
      </p>
      <h2>{t('data_security')}</h2>
      <p>
        {t('data_security_details')}
      </p>
      <h2>{t('your_data_protection_rights')}</h2>
      <p>
        {t('data_protection_rights_details')}
      </p>
      <h2>{t('changes_to_policy')}</h2>
      <p>
        {t('changes_to_policy_details')}
      </p>
      <p>
        {t('contact_us_details')}
      </p>
    </div>
  );
}

export default PrivacyPolicy;