import React, { useRef, useState } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next'; // Add this line
import './Login.css';

export default function Login() {
  const { t } = useTranslation(); // Add this line
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    if (!recaptchaToken) {
      setError(t('complete_recaptcha'));
      return;
    }

    setLoading(true);

    try {
      await login(emailRef.current.value, passwordRef.current.value);
      navigate('/');
    } catch (err) {
      setError(`${t('login_failed')}: ${err.message}`);
    }

    setLoading(false);
  };

  return (
    <div className="login-container">
      <Card className="login-card">
        <Card.Body>
          <h2 className="text-center mb-4">{t('log_in')}</h2>
          {error && <Alert variant="danger" className="mt-2">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email" className="mb-3">
              <Form.Label>{t('email')}</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="password" className="mb-3">
              <Form.Label>{t('password')}</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={setRecaptchaToken}
              className="mb-3"
            />
            <Button disabled={loading} className="w-100 mt-3 btn-primary" type="submit">
              {t('log_in')}
            </Button>
          </Form>
          <div className="text-center mt-3">
            <Link to="/forgot-password">{t('forgot_password')}</Link>
          </div>
        </Card.Body>
      </Card>
      <div className="link-group mt-3 text-center">
        <Link to="/signup" className="d-block">{t('need_to_sign_up')}</Link>
      </div>
    </div>
  );
}
