import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown } from 'react-bootstrap';
import './GetStarted.css';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';

function GetStarted() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [currentWord, setCurrentWord] = useState("Count");
  const [email, setEmail] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    // Set language from localStorage if available
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  console.log('Current Language:', i18n.language);

  const handleSignUpClick = () => {
    console.log('Sign Up button clicked');
    navigate('/signup');
  };

  const handleEmailChange = (event) => {
    console.log('Email input changed:', event.target.value);
    setEmail(event.target.value);
  };

  const handleEmailSubmit = async (event) => {
    event.preventDefault();
    console.log('Email submitted:', email);
    try {
      await addDoc(collection(db, 'RTOWhitelist'), {
        email: email,
        timestamp: new Date()
      });
      alert(t('email_submitted_successfully'));
      console.log('Email submitted successfully');
      setEmail('');
    } catch (error) {
      console.error(t('error_adding_document'), error);
      alert(t('error_submitting_email'));
    }
  };

  const toggleMenu = () => {
    console.log('Menu toggled:', !isMenuOpen);
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const words = [
      t('count'),
      t('matter'),
      t('rewarding'),
      t('worthwhile'),
      t('impactful'),
      t('significant'),
      t('valuable'),
      t('meaningful'),
    ];
    
    console.log('Word list for animation:', words);
  
    let wordIndex = 0;
  
    const interval = setInterval(() => {
      wordIndex = (wordIndex + 1) % words.length;
      console.log('Current word index:', wordIndex, 'Current word:', words[wordIndex]);
      setCurrentWord(words[wordIndex]);
    }, 3000);
  
    return () => {
      console.log('Clearing interval for word animation');
      clearInterval(interval);
    };
  }, [i18n.language, t]);  

  useEffect(() => {
    const handleScroll = () => {
      const aboutSection = document.querySelector('.about-rentfy-section .section-heading');
      const scrollPosition = window.scrollY;
      console.log('Scroll position:', scrollPosition);

      if (aboutSection) {
        const offset = Math.min(scrollPosition / 2, window.innerWidth);
        console.log('About section offset:', offset);
        aboutSection.style.transform = `translateX(${offset}px)`;
      }
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const changeLanguage = (language) => {
    console.log('Changing language to:', language);
    i18n.changeLanguage(language);
    localStorage.setItem('language', language); // Save selected language to localStorage
  };

  return (
    <div className="get-started-container" key={i18n.language}>
      <header className="navbar">
        <div className="navbar-logo">
          <img src={`${process.env.PUBLIC_URL}/rentfy-logo.svg`} alt="Logo" className="logo" />
        </div>
        <div className={`navbar-actions ${isMenuOpen ? 'open' : ''}`}>
          <a href="/need-help" className="navbar-link">{t('help')}</a>
          <a href="/Blog" className="navbar-link">{t('blogs')}</a>
        </div>
        <div className="menu-toggle" onClick={toggleMenu}>
          ☰
        </div>
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            {i18n.language === 'en' ? 'English' : 'Français'}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => changeLanguage('en')}>English</Dropdown.Item>
            <Dropdown.Item onClick={() => changeLanguage('fr')}>Français</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </header>
      <main className="landing-page-content">
        <div className="background-image">
          <img src={`${process.env.PUBLIC_URL}/landing page 1.svg`} alt="Landing Background" className="background-img" />
        </div>
        <div className="text-content">
          <h1 className="main-heading-1">
            {t('make_every_rent_payment')} <span className="word-animation">{currentWord}</span>!
          </h1>
          <p className="subheading-1">{t('automated_rent_payment_description')}</p>
          <Button className="cta-button-2" onClick={handleSignUpClick}>{t('get_started')}</Button>
        </div>
      </main>
      <div className="banner-container">
        <div className="banner-text">
          <span>{t('automated_rent_payments')}</span>
          <span>{t('rent_to_own_program')}</span>
          <span>{t('credit_building')}</span>
          <span>{t('and_more')}</span>
        </div>
      </div>

      <section className="why-choose-us-section">
        <h2 className="section-heading">{t('ready_to_get_started')}</h2>

        <div className="step-container">
          <div className="step left">
            <div className="step-icon">📝</div>
            <h2>{t('step_1')}</h2>
            <h3>{t('apply_in_minutes')}</h3>
            <p>{t('apply_in_minutes_description')}</p>
            <Button href="/signup" className="apply-button">{t('apply_now')}</Button>
          </div>

          <div className="step right">
            <div className="step-icon">📊</div>
            <h2>{t('step_2')}</h2>
            <h3>{t('keep_it_clear_honest')}</h3>
            <p>{t('keep_it_clear_honest_description')}</p>
          </div>

          <div className="step left">
            <div className="step-icon">🔧</div>
            <h2>{t('step_3')}</h2>
            <h3>{t('tailor_perfect_strategy')}</h3>
            <p>{t('tailor_perfect_strategy_description')}</p>
          </div>

          <div className="step right">
            <div className="step-icon">🏠</div>
            <h2>{t('step_4')}</h2>
            <h3>{t('help_find_best_property')}</h3>
            <p>{t('help_find_best_property_description')}</p>
          </div>

          <div className="step left">
            <div className="step-icon">🔑</div>
            <h2>{t('step_5')}</h2>
            <h3>{t('rent_as_future_owner')}</h3>
            <p>{t('rent_as_future_owner_description')}</p>
          </div>

          <div className="step right">
            <div className="step-icon">💵</div>
            <h2>{t('step_6')}</h2>
            <h3>{t('buyback_property')}</h3>
            <p>{t('buyback_property_description')}</p>
            <Button href="/rent-to-own" className="apply-button">{t('learn_more')}</Button>
          </div>
        </div>

        <div className="cta-container">
          <Button href="/signup" className="apply-button">{t('apply_now')}</Button>
        </div>
      </section>

      <section className="faq-container">
        <h2>{t('frequently_asked_questions')}</h2>
        <div className="faq-item">
          <h3>{t('faq_question_1')}</h3>
          <p>{t('faq_answer_1')}</p>
        </div>
        <div className="faq-item">
          <h3>{t('faq_question_2')}</h3>
          <p>{t('faq_answer_2')}</p>
        </div>
        <div className="faq-item">
          <h3>{t('faq_question_3')}</h3>
          <p>{t('faq_answer_3')}</p>
        </div>
        <div className="more-button-container">
          <Button className="more-button" href="/faq">{t('more_faqs')}</Button>
        </div>
      </section>

      {/* Banner below the image */}
      <div className="image-banner">
        <h2>{t('discover_monthly_payments_easier')}</h2>
        <Button href="/information" className="cta-button">{t('learn_more')}</Button>
      </div>

      {/* Email Form Section */}
      <section className="email-form-section">
        <h2>{t('get_free_real_estate_tools')}</h2>
        <form className="email-form" onSubmit={handleEmailSubmit}>
          <input 
            type="email" 
            placeholder={t('enter_your_email')} 
            className="email-input" 
            value={email} 
            onChange={handleEmailChange} 
            required
          />
          <button type="submit" className="email-submit">{t('submit')}</button>
        </form>
      </section>
    </div>
  );
}

export default GetStarted;