import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Blog.css';

function Blog() {
  const { t, i18n } = useTranslation();

  // Load preferred language from localStorage on component mount
  useEffect(() => {
    const savedLanguage = localStorage.getItem('i18nextLng');
    if (savedLanguage && i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const blogPosts = [
    {
      id: 1,
      title: t('blog_1_title'),
      date: 'August 9, 2024',
      content: t('blog_1_content'),
    },
    {
      id: 2,
      title: t('blog_2_title'),
      date: 'August 5, 2024',
      content: t('blog_2_content'),
    },
    {
      id: 3,
      title: t('blog_3_title'),
      date: 'July 28, 2024',
      content: t('blog_3_content'),
    },
  ];

  return (
    <div className="blog-container">
      <header className="blog-header">
        <h1>{t('blog_header_title')}</h1>
        <p>{t('blog_header_description')}</p>
      </header>

      <div className="blog-posts">
        {blogPosts.map((post) => (
          <div key={post.id} className="blog-post">
            <h2>{post.title}</h2>
            <p className="blog-date">{post.date}</p>
            <p>{post.content}</p>
            <a href={`/blog/${post.id}`} className="read-more">
              {t('read_more')}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Blog;
