import React from 'react';
import { useTranslation } from 'react-i18next';

function TestTranslation() {
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t('make_every_rent_payment')}</h1>
      <p>{t('automated_rent_payment_description')}</p>
    </div>
  );
}

export default TestTranslation;
